<template>
  <div class="">
    <v-tabs
      v-model="tab"
      color="primary"
      grow
    >
      <v-tab key="cb">
        BDs
      </v-tab>
      <v-tab key="mc">
        Mini collectors (MC)
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="cb"
        class="pa-0 pb-16"
      >
        <v-card
          elevation="0"
          :loading="loader.catalog"
        >
          <banner></banner>
          <v-card-text class="pa-1 pt-3">
            <v-text-field
              dense
              label="Recherche ..."
              outlined
            ></v-text-field>
          </v-card-text>
        </v-card>
        <div
          v-if="loader.reload"
          class="pa-lg-6 pa-sm-3 text-center"
        >
          <v-btn
            class="ma-auto"
            color="primary"
            @click="init()"
          >
            Recharger
          </v-btn>
        </div>
        <div
          v-else
          class="pa-lg-6 pa-sm-3"
        >
          <v-row v-if="loader.catalog">
            <v-col
              v-for="n in 9"
              :key="'new-'+n"
              class="pa-3"
              lg="1"
              md="2"
              sm="4"
            >
              <v-sheet
                :color="`grey darken-2 `"
              >
                <v-skeleton-loader
                  class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
                  style="margin-left:3px; margin-right:3px; vertical-align: top"
                  :width="columns"
                  max-width="210"
                  min-width="108"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>
          <template
            v-for="item in comicbooks"
            v-else
          >
            <v-card
              class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
              style="margin-left:3px; margin-right:3px; vertical-align: top"
              :width="columns"
              max-width="210"
              min-width="108"
              :to="'/comicbook/'+item.nameseo+'/'+item.id"
              @click="detail(item)"
            >
              <v-img
                max-height="300"
                :src="item.banner"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0 "
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text class="title-inline ma-1 pa-1">
                <b>{{ item.name }}</b><br>
                {{ item.nbchapter }} chapitres
              </v-card-text>
            </v-card>
          </template>
        </div>
      </v-tab-item>
      <v-tab-item
        key="mc"
      >
        list des mcs
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Banner from '@/components/Banner'
// import { useMeta } from 'vue-meta'

export default {
  components: { Banner },
  setup() {
    //useMeta({ title: 'Some Page' })
    const $vuetify = getVuetify()
    const loader = ref({
      catalog: true,
      reload: false,
    })
    const items = ref([])
    const drawer = ref(false)
    const tab = ref('cb')
    const comicbooks = ref([])
    const comicbook = ref({})
    const columns = computed(() => {
      if ($vuetify.breakpoint.md) {
        return '20%'
      }
      if ($vuetify.breakpoint.sm) {
        return '31%'
      }
      if ($vuetify.breakpoint.xs) {
        return '31%'
      }

      return '13%'
    })
    const init = () => {
      loader.value.reload = false
      loader.value.catalog = true
      Drequest.api('lazyloading.comicbook?dfilters=on&status._key:eq=process&lang=fr')
        .get(response => {
          console.log(response)
          comicbooks.value = response.listEntity
          loader.value.catalog = false
        })
        .fail(e => {
          loader.value.reload = true
        })
    }
    init()
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,

      loader,
      tab,
      items,
      comicbooks,
      columns,
      drawer,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
